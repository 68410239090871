import React from 'react';

import AboutCard from '../components/about-card/AboutCard';

const About = () => {
  return (
    <>
      <AboutCard />
    </>
  );
};

export default About;
